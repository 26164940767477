import API from "../common/api-config.service";

export function login(payload) {
	const headers = ({ "Authorization": `Basic ${btoa(payload.email + ":" + payload.password)}` });
	return API({
		method: "POST",
		url: "/auth/token",
		headers: headers
	});
}

export function getProfileDetails(params) {
	return API({
		method: "GET",
		url: "/accounts",
		params
	});
}

export function ssoToken() {
	return API({
		method: "GET",
		url: "/sso/login"
	});
}

export function getToken(params) {
	return API({
		method: "GET",
		url: "/get-token",
		params
	});
}

export function verifyToken(accessToken, tokenType) {
	const headers = ({ "Authorization": `${tokenType} ${accessToken}` });
	return API({
		method: "GET",
		url: "/verify-token",
		headers:headers
	});
}
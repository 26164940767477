import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/PerkinElmer_white-logo-wtagline.svg";
import userStore from "../../common/user-store";
import { LINK_FIELDS } from "../sidemenu/model";
import Const from "../../common/constants.js";

class Sidemenu extends React.Component {
	static get defaultProps() {
		return {
			pageTitle: null
		};
	}

	constructor(props) {
		super(props);
		this.state = {
			location: "",
			ownClinics: null,
			showSubMenu: false,
			role: userStore.get("Role"),
			selectedBrand: userStore.get("SelectedBrand") || userStore.get("BrandName"),
			brandName: ["Caterpillar", "Abbvie", "Tempus"],
			consumableFlag: userStore.get("ConsumableFlag")
		};

	}

	toggleSidebar = () => {
		let { toggleDrawer } = this.props;
		toggleDrawer();
	};

	//Redirect to Link
	renderlinkItem = (l, indx, location) => {

		return (
			<li key={indx} className="slide">
				{l.redirectURL ?
					<a className={`side-menu__item d-flex align-items-center px-3 py-2 p-md-3 mt-1 ${location.includes(l.link) ? " active" : ""}`} data-toggle="slide" href={l.redirectURL} onClick={this.toggleSidebar} target="_blank" rel="noopener noreferrer">
						{l.image !== "" ?
							location.includes(l.link) ?
								<img src={require(`../../assets/icons/active/icn-${l.image}_active.svg`)} alt="--" className="ml-2 mr-3 side-menu__icon" />
								:
								<img src={require(`../../assets/icons/inactive/icn-${l.image}_inactive.svg`)} alt="--" className="ml-2 mr-3 side-menu__icon" />
							:
							<i className={`angle la ${l.icon} p-1 mr-3`} style={{ fontSize: "18px" }}></i>
						}
						<span className="side-menu__label">{l.label}</span>
						<i className="angle fa fa-angle-right p-1 ml-auto"></i>
					</a>
					:
					<Link className={`side-menu__item d-flex align-items-center px-3 py-2 p-md-3 mt-1 ${location.includes(l.link) ? " active" : ""}`} data-toggle="slide" to={l.link} onClick={() => {
						this.setState({ showSubMenu: l.isSubMenu }, () => {
							if (l.link != "/locations") {
								userStore.remove("MyLocationTab");
							}
							if (l.link != "/my-instrument") {
								userStore.remove("MyInstrumentsTab");
							}
						});
					}} >
						{l.image !== "" ?
							location.includes(l.link) ?
								<img src={require(`../../assets/icons/active/icn-${l.image}_active.svg`)} alt="--" className="ml-2 mr-3 side-menu__icon" />
								:
								<img src={require(`../../assets/icons/inactive/icn-${l.image}_inactive.svg`)} alt="--" className="ml-2 mr-3 side-menu__icon" />
							:
							<i className={`angle la ${l.icon} p-1 mr-3`} style={{ fontSize: "18px" }}></i>
						}
						<span className="side-menu__label">{l.label}</span>
						<i className="angle fa fa-angle-right p-1 ml-auto"></i>
					</Link>
				}
				{l.isSubMenu &&
					<ul className={` pl-4 sub-menu slide-menu ${this.state.showSubMenu ? "d-block" : "d-none"} `}>
						{l.subMenu && l.subMenu.map((s, i) => {
							return (
								<li key={i} className={`slide mt-1 ${s.label === "Service Parts" ? s.role === this.state.role ? "" : "d-none" : ""}`}><Link to={s.link} className="slide-item px-4 py-1 active" onClick={this.toggleSidebar}>{s.label}</Link></li>
							);
						})}
					</ul>
				}
			</li>
		);
	};

	//Show All Link of Sidemenu
	showLink = (brand, role, location) => {
		let items = LINK_FIELDS.filter(l => l.brand === brand && role.some(r => r === l.role));
		//Product Website and Request Service Hidden for SGS Brand
		if (this.state.selectedBrand == Const.SIDEMENU.SGS_BRAND) {
			items = items.filter(l => !l.redirectURL);
		}
		return items && items.map((l, indx) => {
			if (l.label == Const.SIDEMENU.LABEL) {
				if (!this.state.brandName.includes(this.state.selectedBrand)) {
					if (this.state.consumableFlag === Const.SIDEMENU.CONSUMABLE_FLAG) {
						l.label = "Product Website";
					}
					return this.renderlinkItem(l, indx, location);
				}
			}
			else {
				return this.renderlinkItem(l, indx, location);
			}
		});
	}

	//Redirect to Consumable Link
	renderConsumableLink = (consumableFlag, role, location) => {
		let links = [
			{ brand: "c360", role: "Customer" },
			{ brand: "c360", role: "Internal-Consumable" }
		];
		if (consumableFlag === Const.SIDEMENU.CONSUMABLE_FLAG) {
			if (role === Const.ROLE.CUSTOMER) {
				return links.map(l => this.showLink(l.brand, l.role, location));
			}
			else {
				return this.showLink("c360", "Internal-Consumable", location);
			}
		}
	}

	//Set Roles
	setRoles = (role, consumableFlag) => {
		if (consumableFlag === Const.SIDEMENU.CONSUMABLE_FLAG) {
			if (role === Const.ROLE.CUSTOMER) {
				return ["All", "Customer", "Internal-Consumable"];
			}
			else {
				return ["All", "Internal-Consumable"];
			}
		}
		else {
			return ["All"];
		}
	}

	render() {
		let location = this.props && this.props.path;
		let { role, consumableFlag } = this.state;
		location = location.toString();

		return (
			<div className="app-sidebar__user">
				<div className="dropdown">
					<a className="nav-link px-3 py-0 py-xl-2 pb-xl-3 leading-none d-flex w-100 justify-content-center" href="http://www.perkinelmer.com" style={{ borderBottom: "1.5px solid #09099c" }}>
						<img src={logo} alt="" className="sidemenu-banner pt-2 pb-1 pb-md-0" style={{ width: "12vw" }} />
					</a>
				</div>

				{/* SHOW ONLY FOR PERKIN ELMER PERSON */}
				{!(location.includes("/new-order") || location.includes("/welcome")) &&
					<ul className="side-menu  mt-2 mt-md-0">
						{this.showLink("c360",["Internal-All"],location)}
						{role === "Internal" && this.showLink("c360", ["Internal"], location)}
						{this.showLink("c360", this.setRoles(role, consumableFlag), location)}
						{/* {this.renderConsumableLink(consumableFlag, role, location)} */}
					</ul>
				}
				{(location.includes("/new-order") || location.includes("/welcome")) &&
					<ul className="side-menu  mt-2 mt-md-5 mb-1">
						{this.showLink("cep", ["All"], location)}
					</ul>
				}
				<div className={`w-100 text-center ${location.includes("/new-order") || location.includes("/welcome") ? " mt-md-5" : ""}`} style={{ position: "absolute", bottom: "60px" }}>
					{(location.includes("/new-order") || location.includes("/welcome")) &&
						<Link to='/my-instruments' className="p-0 m-0 w-100" onClick={this.toggleSidebar}>
							<button type="button" className="btn bg-white z-depth-0 side-menu__btn waves-effect waves-light" style={{ padding: ".5rem 1.9rem" }}>
								My Installed Base
							</button>
						</Link>
					}
					<Link to='/contact-us' className="p-0 m-0 w-100" onClick={this.toggleSidebar}>
						<button type="button" className="btn px-5 py-2 bg-white z-depth-0 side-menu__btn waves-effect waves-light">
							Contact Us
						</button>
					</Link>
				</div>
			</div>
		);
	}
}

export default Sidemenu;
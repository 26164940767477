import React from "react";
import logo from "../../assets/img/PerkinElmer_white-logo.svg";

const Footer = () => {
	return (
		<footer className="page-footer py-2 text-center font-small">
			<div className="footer-copyright bg-transparent my-0 text-white d-flex align-items-center justify-content-end">
				<a href="https://www.perkinelmer.com/" target="_blank" rel="noopener noreferrer" style={{ margin: "0 1rem" }}>
					<img src={logo} alt="Logo" className="img-fluid d-inline mx-2" style={{ width: "60px" }} />
				</a>
			</div>
		</footer>
	);
};

export default Footer;
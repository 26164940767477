import React from "react";
import $ from "jquery";
import userStore from "../../common/user-store";
import { Link } from "react-router-dom";
import logoutIcon from "../../assets/icons/icn-logout.svg";
import profileIcon from "../../assets/icons/icn-profile_grey.svg";
import profileImg from "../../assets/img/Profile_avatar.png";
import hamburger from "../../assets/icons/icn-hamburger-menu.svg";
import customerLogo from "../../assets/img/Customer-360-logo_option1.jpg";
import * as ProfileDetailsAPI from "../../api/auth-api";


class Header extends React.Component {
	static get defaultProps() {
		return {
			pageTitle: null,
			profileDetails: ""
		};
	}

	constructor(props) {
		super(props);
		this.state = {
			profilePicture: "",
			customer: userStore.get("Customer"),
			brandname: userStore.get("BrandName"),
			role: userStore.get("Role"),
			fullname: userStore.get("FullName"),
			email: userStore.get("Email"),
			location: "",
			Brand: ""
		};
	}

	componentDidMount() {
		this.setState({ location: this.props.props.location.pathname });
		$("#selectdBrand").val(userStore.get("SelectedBrand"));
	}

	//Get Profile Details
	getProfileDetails = () => {
		let params = {
			email: userStore.get("Email"),
			account_id: userStore.get("account_id"),
			contact_id: userStore.get("contact_id")
		};
		ProfileDetailsAPI.getProfileDetails(params).then(
			response => {
				let data = response.data && response.data.data && response.data.data[0];
				this.setState({ loading: false, profileDetails: data });
			},
			() => {
				this.setState({ loading: false });
			}
		);
	}

	toggleSidebar = () => {
		let { toggleDrawer } = this.props;
		toggleDrawer();
	};

	//Change Brand Name
	selectedBrand = (event) => {
		const brand = event.target.value;
		userStore.let("SelectedBrand", brand);
		window.location.reload();
	}

	render() {
		let { brandname, role, fullname, email } = this.state;
		return (
			<nav className="navbar navbar-expand-lg main-navbar dashboard-header justify-content-center py-0">
				<form className="form-inline mr-auto">
					<ul className="navbar-nav py-2">
						<li>
							<a href="#" data-toggle="sidebar" className=" pl-0 hamburger nav-link nav-link-lg py-0 pt-2" onClick={this.toggleSidebar}>
								<img src={hamburger} alt="--" className="ml-2 mr-3 hamburger__icon" />
							</a>
						</li>
						<li className="d-none d-md-block pt-1">
							<div className="form-inline px-1 header-form position-relative">
								<p className="header-title text-left mb-1 mb-md-2 mb-xl-1 px-md-1" style={{ height: "inherit" }}>
									{role !== null && role === "Internal" ? (
										<select name="brand" id="selectdBrand" style={{ border: "none", color: "#0055a6" }} onChange={this.selectedBrand}>
											{brandname.map((brandname,i) => {
												return (
													<option key={i} value={brandname}>{brandname}</option>
												);
											})}
										</select>
									) : brandname
									}
								</p>
							</div>
						</li>
					</ul>
					<div className=" form-inline mr-auto horizontal" id="headerMenuCollapse">
						<div className="d-none d-lg-block">
						</div>
					</div>
				</form>
				<a className="d-md-block text-center position-absolute p-2 px-md-4" href="#">
					{/* <h4 style={{ "marginBottom": "-3px" }}>Perkin Elmer</h4> */}
					<img src={customerLogo} className="header-brand-img my-0 mx-auto" alt="PerkinElmer" style={{ height: "3.5rem" }} />
				</a>
				<ul className="navbar-nav navbar-right pt-0">
					<li className="dropdown dropdown-list-toggle d-block">
						<a href="#" data-toggle="dropdown" className="user-dropdown nav-link dropdown-toggle nav-link-lg p-0 pt-1 d-block d-md-none">
							<img src={profileImg} alt="--" style={{ width: "30px", borderRadius: "50%" }} className="ml-2 mr-1" />
						</a>
						<a href="#" data-toggle="dropdown" className="user-dropdown nav-link dropdown-toggle nav-link-lg p-0 pt-1 d-none d-md-block">
							{fullname !== null ? fullname : email}
						</a>
						<div className="dropdown-menu dropdown-menu-right rounded-0">
							<Link to={"/profile"} className="dropdown-item has-icon">
								<img src={profileIcon} alt="--" className="ml-2 mr-3 navbar-dropdown__icon" />
								<span>Profile</span>
							</Link>
							<Link to={"#"} className="dropdown-item has-icon" onClick={() => { userStore.logout(); }}>
								<img src={logoutIcon} alt="--" className="ml-2 mr-3 navbar-dropdown__icon" />
								<span>Logout</span>
							</Link>
						</div>
					</li>
				</ul>
			</nav>
		);
	}
}

export default Header;
export const LINK_FIELDS = [
	{ brand: "c360", role: "Internal-All", isSubMenu: false, link: "/my-perkinelmer-lab", icon: "", image: "order", label: "My PerkinElmer Lab" },
	{ brand: "c360", role: "Internal", isSubMenu: false, link: "/company/dashboard", icon: "la-address-card", image: "", label: "My Customers" },
	{ brand: "c360", role: "All", isSubMenu: false, link: "/my-instruments", icon: "", image: "instrument-list", label: "My Instruments" },
	{ brand: "c360", role: "All", isSubMenu: false, link: "/locations", icon: "la-map-marker", image: "", label: "My Locations" },
	{
		brand: "c360", role: "All", isSubMenu: true, link: "/search-orders", icon: "", image: "order-history", label: "My Orders",
		subMenu: [
			{ role: "All", link: "/order-history/instruments", label: " Instruments" },
			{ role: "All", link: "/order-history/minor-accessories", label: "Minor Accessories" },
			{ role: "All", link: "/order-history/consumables", label: "Reagents and Consumables" },
			{ role: "Internal", link: "/order-history/spare", label: "Service Parts" }
		]
	},
	{ brand: "c360", role: "All", isSubMenu: false, link: "/order-consumables", redirectURL:"https://login.microsoftonline.com/f9f27055-d80d-47df-abb2-fa20cc88e4e1/oauth2/v2.0/authorize?client_id=a354a44d-aebb-4484-9b5e-d50704a50b82&redirect_uri=https%3a%2f%2fsupport.perkinelmer.com%2faccount%2fAccount%2fExternalOAuthCallback%3fprovider%3dperkinelmer&scope=openid%20email&response_type=code", icon: "la-user-cog", image: "", label: "Request Service" },
	{ brand: "c360", role: "Customer", isSubMenu: false, link: "/my-consumables", icon: "", image: "order", label: "Ordering My Consumables" },
	{ brand: "c360", role: "All", isSubMenu: false, link: "/order-consumables", redirectURL:"https://www.perkinelmer.com/buy", icon: "", image: "order", label: "Order Reagents and Consumables" },
	{ brand: "c360", role: "All", isSubMenu: false, link: "/events", icon: "", image: "upcoming-event", label: "Perkinelmer Events" },
	{ brand: "c360", role: "Internal-Consumable", isSubMenu: false, link: "/consumables-orders", icon: "", image: "order-history", label: "Consumables Orders" }
];
import React from "react";
// import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { render } from "react-dom";
import routes from "./routes/routes";
import "./assets/css/style.css";
import "./assets/css/loader.scss";
import "./assets/css/timeline.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/mdb.min.css";

import "jquery/dist/jquery.min.js"; // jquery script
import "bootstrap/dist/js/bootstrap.min.js"; // bootstrap script

import "../node_modules/react-notifications/src/notifications.scss";
import "react-rangeslider/lib/index.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "react-widgets/dist/css/react-widgets.css";
import * as serviceWorker from "./serviceWorker";
   
render(<Router>{routes}</Router>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import HomeHeader from "../components/shared/home-header";
import Header from "../components/shared/header";
import Footer from "../components/shared/footer";
import { NotificationContainer } from "react-notifications";
import DoctorSidemenu from "../components/sidemenu/sidemenu";
import { getWindowDimensions } from "../common/window-dimensions.helper";

class Layout extends React.Component {
	constructor(props) {
		super(props);

		let { width } = getWindowDimensions();
		this.state = {
			isDrawerOpen: width > 991,
			width: width
		};
	}

    toggleDrawer = isDrawerOpen => {
    	let { width } = this.state;
    	if (width < 1024) {
    		if (typeof isDrawerOpen === "undefined") {
    			isDrawerOpen = !this.state.isDrawerOpen;
    		}
    		this.setState({ isDrawerOpen: isDrawerOpen });
    	}
    };

    toggleDrawerViaHeader = isDrawerOpen => {
    	if (typeof isDrawerOpen === "undefined") {
    		isDrawerOpen = !this.state.isDrawerOpen;
    	}
    	this.setState({ isDrawerOpen: isDrawerOpen });
    };

    render() {
    	let { children } = this.props;

    	let { isDrawerOpen } = this.state;
    	return (
    		<React.Fragment>
    			<main className={"page-wrapper page-layout page-layout-dashboard position-relative"}>
    				<div className={`drawer-overlay ${isDrawerOpen ? "show-drawer" : ""}`} onClick={() => this.toggleDrawer(false)} />
    				{children.props.pageType === "Home" ?
    					<header className={` page-header ${isDrawerOpen ? "show-drawer" : ""}`}>
    						<HomeHeader />
    					</header>
    					:
    					<header className={`page-header dashboard-header ${isDrawerOpen ? "show-drawer" : ""}`}>
    						<Header props={children.props} toggleDrawer={this.toggleDrawerViaHeader} />
    					</header>
    				}
    				{children.props.pageType === "Home" ?
    					<aside className={`page-sidebar ${isDrawerOpen ? "show-drawer" : ""}`}>
    					</aside>
    					:
    					<aside className={`page-sidebar dashboard-sidebar pt-0 ${isDrawerOpen ? "show-drawer" : ""}`}>
    						<DoctorSidemenu toggleDrawer={this.toggleDrawer} path={children.props.location.pathname} />
    					</aside>
    				}
    				{children.props.pageType === "Home" ?
    					<div className={`page-content component-loading ${isDrawerOpen ? "show-drawer" : ""} ${window.location.href.includes("/home")?"p-0":""}`}>
    						{children}
    					</div>
    					:
    					<div className={` page-content component-loading dashboard-content ${isDrawerOpen ? "show-drawer" : ""} app-content py-3 pt-md-4 pb-md-5`}>
    						{children}
    					</div>
    				}
    				{children.props.pageType === "Home" ?
    					<>
    					</>
    					:
    					<div className={`my-0 page-footer-div ${isDrawerOpen ? "show-drawer" : ""}`}>
    						<Footer/>
    					</div>
    				}
    			</main>

    			<NotificationContainer className="notification-box" />
    		</React.Fragment>
    	);
    }
}

// const Layout = ({ children }) => (
//     <div id="wrapper">


//         <div className="component-loading">

//             {children}
//         </div>
//         {/* <Footer /> */}


//     </div>
// );

export default Layout;
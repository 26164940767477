export default {
	API: {
		Url: "https://c360devapi-v2.quolam.com/v1"
	},
	PAGE_LIMIT: 10,
	SLIDER_SETTINGS: {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	},
	INSTRUMENT_SUMMARY_SLIDER_SETTINGS: {
		dots: true,
		arrows: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	},
	QUANTITY_FLAG: { FLAG: "add" },
	SIDEMENU: { CONSUMABLE_FLAG: "Y", LABEL: "Order Reagents and Consumables", SGS_BRAND: "SGS" },
	ROLE: { CUSTOMER: "customer", INTERNAL: "Internal" },
	FILTER_PERIOD: { MONTHS_3: "Past 3 Months", MONTHS_6: "Past 6 Months", MONTHS_12: "Past 12 Months", MONTHS_ALL: "Show All" },
	IS_ARCHIVE: { ARCHIVE: "archive" },
	ORDER_STATUS: { OPEN: "Open Orders", SHIPPED: "Shipped", PARTIAL: "Partially processed", NOT_YET: "Not yet processed", COMPLETELY: "Completely processed", DELIVERED: "Delivered" },
	EMETHOD_SYSTEM: {
		QSIGHT_TITLE: "Analysis of Mycotoxins in Multigrains and Corn Cereals",
		QSIGHT1_TITLE: "Analysis of Aflatoxins in Traditional Chinese Medicine Coix Seed Using LC/MS/MS",
		QSIGHT2_TITLE: "Qualitative identification of tortoise-shell glue using LC/MS/MS",
		QSIGHT3_TITLE: "Rapid Analysis of Gelatinous Traditional Chinese Medicines Using an Epic C18 UHPLC Column",
		FLEXAR_TITLE: "Fast Analysis of 18 PAHs Using a Quasar SPP PAH Column",
		NEXION_TITLE: "Ultra-Trace Quantification of Non-Metals in Sulfuric Acid Solutions Using the NexION 5000 ICP-MS Under Different Cell Gas Conditions",
		NEXION1_TITLE: "Ultra-trace Determination of Non-metallic Elements in Dilute Nitric Acid Using NexION 5000 ICP-MS",
		NEXION2_TITLE: "Rapid Ultra-Trace Analysis of Impurities in Ultrapure Water using the NexION 5000 ICP-MS",
		GC2400_1_TITLE: "USP-NF Cetyl Alcohol Monograph Using the PerkinElmer™ GC 2400© Gas Chromatograph",
		GC2400_2_TITLE: "Analysis of a Commercial Flex Fuel Gasoline (E85) by ASTM Method D5501-12 using the PerkinElmer™ GC 2400© Gas Chromatograph",
		LC300_1_TITLE: "Analysis of Common Preservatives in Personal Care Products by HPLC with UV Detection",
		LC300_2_TITLE: "HPLC Analysis of Acetaminophen, Aspirin and Caffeine Using an Epic C18 Column in Accordance with the United States Pharmacopeia",
		LC300_3_TITLE: "HPLC Analysis of Finasteride Using an Epic C18 Column in Accordance with the United States Pharmacopeia",
		LC300_4_TITLE: "HPLC Analysis of Lamotrigine Using an Epic C18 SD Column in Accordance with the United States Pharmacopeia",
		LC300_5_TITLE: "Analysis of Phytoestrogen Isoflavones in Dietary Supplements by HPLC/UV"
	},
	EVENT_ACTION: {
		HIDE: "hide",
		UNHIDE: "unhide"
	},
	PAYMENT_FILTER_AMOUNT: {
		LESS_10000: "less than $10000",
		MORE_10000: "$10000 to $100,000"
	}
};


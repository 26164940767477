import React from "react";
import UserStore from "../../common/user-store";
import { Link } from "react-router-dom";
import logo from "../../assets/img/PKI_FTB_Logo_Color_Transparent.png";
import whiteLogo from "../../assets/img/PerkinElmer_white-logo-wtagline.svg";
import customerLogo from "../../assets/img/Customer-360-logo_option1.jpg";
import logoutIcon from "../../assets/icons/icn-logout.svg";
import profileIcon from "../../assets/icons/icn-profile_grey.svg";

class HomeHeader extends React.Component {
	static get defaultProps() {
		return {
			pageTitle: null
		};
	}

	constructor(props) {
		super(props);
		this.state = {
			profilePicture: "",
			customer: UserStore.get("Customer"),
			BrandName: UserStore.get("BrandName"),
			role: UserStore.get("Role"),
			fullname:UserStore.get("FullName"),
			email: UserStore.get("Email"),
			location: ""
		};
	}

	render() {
		let { fullname,email } = this.state;
		return (
			<div>
				<nav className={`navbar navbar-home navbar-expand-lg main-navbar text-dark p-0 px-2 ${window.location.href.includes("/home") ? "bg-transparent z-depth-0" : "bg-white"}`} style={{ height: "80px", justifyContent: window.location.href.includes("/login") ? "center" : "space-evenly" }}>
					<a className={`header-brand d-md-block p-2 ${window.location.href.includes("/home") ? "px-md-3 text-left" : "px-md-4 text-center"}`} style={{ height: "100%" }} href="#">
						<img src={window.location.href.includes("/home") ? whiteLogo : logo} className="header-brand-img my-2 mx-auto" alt="PerkinElmer" style={{ height: "3.5rem" }} />
					</a>
					<a className={`header-brand  ${window.location.href.includes("/login") || window.location.href.includes("/home") || window.location.href.includes("/get-token") ? " d-none d-md-none" : " d-md-block text-center position-absolute"} pt-3 p-2 px-md-3`} style={{ height: "100%" }} href="#">
						<img src={customerLogo} className="header-brand-img my-0 mx-auto" alt="PerkinElmer" style={{ height: "3.5rem" }} />
					</a>
					{(UserStore.get("BrandName") !== null && UserStore.get("BrandName") !== undefined) &&
                        <ul className="navbar-nav navbar-right ml-auto">
                        	<li className={`dropdown dropdown-list-toggle ${window.location.href.includes("/home") ? "d-none" : "d-block"}`}>
                        		<a href="#" data-toggle="dropdown" className="user-dropdown nav-link dropdown-toggle nav-link-lg p-0 pt-1 ">
                        			{fullname !== null ? fullname : email}
                        		</a>
                        		<div className="dropdown-menu dropdown-menu-right rounded-0">
                        			<Link to={"/profile"} className="dropdown-item has-icon">
                        				<img src={profileIcon} alt="--" className="ml-2 mr-3 navbar-dropdown__icon" />
                        				<span>Profile</span>
                        			</Link>
                        			<Link to={"#"} className="dropdown-item has-icon" onClick={() => { UserStore.logout(); }}>
                        				<img src={logoutIcon} alt="--" className="ml-2 mr-3 navbar-dropdown__icon" />
                        				<span>Logout</span>
                        			</Link>
                        		</div>
                        	</li>
                        </ul>

					}

				</nav>
			</div>
		);
	}
}

export default HomeHeader;
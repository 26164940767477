import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import UserStore from "../common/user-store";
import Layout from "../shared/layout";
import Async from "react-code-splitting";

const LoginRoute = ({ component: Component, pageType, ...rest }) => (
	<Route {...rest} render={props => (
		UserStore.loggedin() && UserStore.get("Role") === "Customer" ? (
			<Redirect to={{
				pathname: "/my-instruments",
				state: { from: props.location }
			}} />
		) : UserStore.loggedin() && UserStore.get("Role") === "Internal" ? (
			<Redirect to={{
				pathname: "/company/dashboard",
				state: { from: props.location }
			}} />
		) :
			(
				<Layout>
					<Component pageType={pageType} {...props} />
				</Layout>
			)
	)} />
);

const PrivateRoute = ({ component: Component, pageType, ...rest }) => (
	<Route {...rest} render={props => (
		UserStore.loggedin() ? (
			<Layout>
				<Component pageType={pageType} {...props} />
			</Layout>
		) : (
			<Redirect to={{
				pathname: "/login",
				state: { from: props.location }
			}} />
		)
	)} />
);

const AllInstruments = props => (
	<Async load={import("../views/MyAllInstruments")} componentProps={props} />
);

const Login = props => (
	<Async load={import("../views/Auth/Login")} componentProps={props} />
);
const SSOLogin = props => (
	<Async load={import("../views/Auth/SSOLogin")} componentProps={props} />
);

const Landing = props => (
	<Async load={import("../views/Landing")} componentProps={props} />
);

const MyInstruments = props => (
	<Async load={import("../views/MyInstruments/MyInstruments")} componentProps={props} />
);

const SearchOrders = props => (
	<Async load={import("../views/SearchOrders")} componentProps={props} />
);

const MyInstrumentDetail = props => (
	<Async load={import("../views/MyInstrumentDetail/MyInstrumentDetail")} componentProps={props} />
);

const MyLocations = props => (
	<Async load={import("../views/MyLocation/MyLocations")} componentProps={props} />
);

const OrderHistoryInstruments = props => (
	<Async load={import("../views/OrderHistory/Instruments/OrderHistoryInstruments")} componentProps={props} />
);

const OrderHistoryConsumables = props => (
	<Async load={import("../views/OrderHistory/Consumables/OrderHistoryConsumables")} componentProps={props} />
);

const OrderHistoryMinorAccessories = props => (
	<Async load={import("../views/OrderHistory/MinorAccessories/OrderHistoryMinorAccessories")} componentProps={props} />
);
const OrderHistorySpareParts = props => (
	<Async load={import("../views/OrderHistory/SpareParts/OrderHistorySpareParts")} componentProps={props} />
);

const PendingPayments = props => (
	<Async load={import("../views/PendingPayments")} componentProps={props} />
);

const ContactUs = props => (
	<Async load={import("../views/ContactUs")} componentProps={props} />
);

const Feedback = props => (
	<Async load={import("../views/Feedback")} componentProps={props} />
);

const Events = props => (
	<Async load={import("../views/MyEvents")} componentProps={props} />
);

const Profile = props => (
	<Async load={import("../views/MyProfile")} componentProps={props} />
);

const CompanyDashboard = props => (
	<Async load={import("../views/Customers/AllCustomerList")} componentProps={props} />
);

const NewOrder = props => (
	<Async load={import("../views/NewOrder")} componentProps={props} />
);

const MyConsumables = props => (
	<Async load={import("../views/MyConsumables")} componentProps={props} />
);

const MyCart = props => (
	<Async load={import("../views/MyCart/MyCart")} componentProps={props} />
);

const ConsumablesOrders = props => (
	<Async load={import("../views/ConsumablesOrders/ConsumablesOrders")} componentProps={props} />
);

const EMethodsList = props => (
	<Async load={import("../views/EMethodsList")} componentProps={props} />
);

const EMethodsDetail = props => (
	<Async load={import("../views/EMethodsDetail")} componentProps={props} />
);

const Welcome = props => (
	<Async load={import("../views/Welcome")} componentProps={props} />
);

const MyPerkinelmerLab = props => (
	<Async load={import("../views/MyPerkinelmerLab")} componentProps={props} />
);

const Utilities = props => (
	<Async load={import("../views/Utilities")} componentProps={props} />
);

const routes = (
	<Switch>
		<PrivateRoute exact path="/company/dashboard" component={CompanyDashboard} pageType="Home" />
		<PrivateRoute exact path="/events" component={Events} pageType="Dashboard" />
		<PrivateRoute exact path="/profile" component={Profile} pageType="Dashboard" />
		<PrivateRoute exact path="/feedback" component={Feedback} pageType="Dashboard" />
		<PrivateRoute exact path="/contact-us" component={ContactUs} pageType="Dashboard" />
		<PrivateRoute exact path="/pending-payments" component={PendingPayments} pageType="Dashboard" />
		<PrivateRoute exact path="/order-history/spare" component={OrderHistorySpareParts} pageType="Dashboard" />
		<PrivateRoute exact path="/order-history/consumables" component={OrderHistoryConsumables} pageType="Dashboard" />
		<PrivateRoute exact path="/order-history/minor-accessories" component={OrderHistoryMinorAccessories} pageType="Dashboard" />
		<PrivateRoute exact path="/order-history/instruments/:salesOrder" component={OrderHistoryInstruments} pageType="Dashboard" />
		<PrivateRoute exact path="/order-history/instruments" component={OrderHistoryInstruments} pageType="Dashboard" />
		<PrivateRoute exact path="/my-instrument/detail" component={MyInstrumentDetail} pageType="Dashboard" />
		<PrivateRoute exact path="/my-instrument" component={MyInstruments} pageType="Dashboard" />
		<PrivateRoute exact path="/my-instruments" component={AllInstruments} pageType="Dashboard" />
		<PrivateRoute exact path="/search-orders" component={SearchOrders} pageType="Dashboard" />
		<PrivateRoute exact path="/locations" component={MyLocations} pageType="Dashboard" />
		<PrivateRoute exact path="/home" component={Landing} pageType="Home" />
		<PrivateRoute exact path="/order-consumables" component={OrderHistoryConsumables} pageType="Dashboard" />
		<PrivateRoute exact path="/welcome" component={Welcome} pageType="Dashboard" />
		<PrivateRoute exact path="/new-order" component={NewOrder} pageType="Dashboard" />
		<PrivateRoute exact path="/my-consumables" component={MyConsumables} pageType="Dashboard" />
		<PrivateRoute exact path="/my-cart" component={MyCart} pageType="Dashboard" />
		<PrivateRoute exact path="/consumables-orders" component={ConsumablesOrders} pageType="Dashboard" />
		<LoginRoute exact path="/get-token" component={SSOLogin} pageType="Home" />
		<LoginRoute exact path="/login" component={Login} pageType="Home" />
		<PrivateRoute exact path="/my-perkinelmer-lab/e-methods" component={EMethodsDetail} pageType="Dashboard" />
		<PrivateRoute exact path="/my-perkinelmer-lab/:name/e-methods" component={EMethodsList} pageType="Dashboard" />
		<PrivateRoute exact path="/my-perkinelmer-lab" component={MyPerkinelmerLab} pageType="Dashboard" />
		<PrivateRoute exact path="/my-instrument/e-methods" component={EMethodsDetail} pageType="Dashboard" />
		<PrivateRoute exact path="/my-instrument/:name/e-methods" component={EMethodsList} pageType="Dashboard" />
		<PrivateRoute exact path="/utilities" component={Utilities} pageType="Utilities" />
		{/* <PublicRoute exact path="/order-history/spare" component={AllInstruments} pageType="Dashboard" />
        <PublicRoute exact path="/order-history/services" component={AllInstruments} pageType="Dashboard" /> */}
		<Redirect exact from="/" to="/login" />
		<Redirect to='/' />
	</Switch>
);

export default routes;
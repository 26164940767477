import axios from "axios";
import Const from "./constants";
import UserStore from "./user-store";
import { NotificationManager as notify } from "react-notifications";

//Create axios instance
let instance = axios.create({
	baseURL: Const.API.Url,
	headers: { "Content-Type": "application/json" }
});

instance.interceptors.request.use(
	function(config) {
		//Check if user is logged in. If yes, then use Bearer Token from localstorage
		if (UserStore.loggedin()) {
			config.headers.Authorization = `Bearer ${UserStore.get("Token")}`;
		}

		return config;
	},
	function(error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	function(response) {
		return response;
	},
	function(error) {
		if (
			error.response &&
      error.response.status &&
      error.response.status === 403 &&
      !(window.location.href.includes("/get-token"))
		) {
			notify.warning("Kindly login again.", "Session Expired");
			UserStore.logout();
		}
		return Promise.reject(error.response);
	}
);

export default instance;

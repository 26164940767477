import moment from "moment";

class User {
	set(data) {
		data.TokenExpiry = moment().add(1, "day").toDate(); // TODO: match the utc expiry
		// data.token = token.token; // TODO: match the utc expiry
		let user = Buffer(JSON.stringify(data)).toString("hex");
		window.localStorage.setItem("user", user);
	}

	get(prop) {
		let user = window.localStorage.getItem("user");
		if (user !== null) {
			const decoded = Buffer.from(user, "hex");
			return JSON.parse(decoded)[prop];
		}
		else {
			return null;
		}
	}
	getProfile(prop) {
		let user = window.localStorage.getItem("user");
		if (user !== null) {
			const decoded = Buffer.from(user, "hex");
			return JSON.parse(decoded)["profile"][prop];
		}
		else {
			return null;
		}
	}

	let(key, value) {
		let user = window.localStorage.getItem("user");
		if (user !== null) {
			const decoded = Buffer.from(user, "hex");
			let store = JSON.parse(decoded);
			store[key] = value;
			let encodedStore = Buffer(JSON.stringify(store)).toString("hex");
			window.localStorage.setItem("user", encodedStore);
		}
	}

	remove(prop) {
		let user = window.localStorage.getItem("user");
		if (user !== null) {
			const decoded = Buffer.from(user, "hex");
			let store = JSON.parse(decoded);
			delete store[prop];
			let encodedStore = Buffer(JSON.stringify(store)).toString("hex");
			window.localStorage.setItem("user", encodedStore);
		}
	}

	loggedin() {

		let user = window.localStorage.getItem("user");
		if (user !== null)
			return true;
		else
			return false;
	}

	logout() {
		window.localStorage.clear();
		window.location.href = "/";
	}
}

let userStore = window.UserStore = new User;
export default userStore;